/* eslint-disable consistent-return */
import { useEffect, useState } from "react";

import appAPI from "@api/application";
import { CompilationsByType } from "@interfaces/api";
import { DEFAULT_PAGE_PARAMS } from "@constants/common";
import { BANNER_RANK_VALUES } from "@constants/compilation";
import { compilationsSelector } from "@store/selectors/compilations";

const RECOMENDED_CHUNK_SIZE = 6;

const useCompilationsFeed = (page: number) => {
  const { compilations, isCompilationsLoading, isCompilationsEmpty } =
    compilationsSelector();

  const [isAppsLoading, setIsAppsLoading] = useState(true);
  const [compilationsFeed, setCompilationsFeed] = useState<CompilationsByType>({
    banner: [],
    usual: [],
  });

  useEffect(() => {
    if (isCompilationsLoading) {
      return;
    }

    if (isCompilationsEmpty) {
      setIsAppsLoading(false);
      return;
    }

    const fetchAppsByCompilations = async () => {
      try {
        const compilationsWithApps = await Promise.all(
          compilations
            .slice(
              RECOMENDED_CHUNK_SIZE * (page - 1),
              RECOMENDED_CHUNK_SIZE * page,
            )
            .map(async (compilation) => {
              const { id, rank, iconRef, compilationBannerRef } = compilation;

              if (BANNER_RANK_VALUES.includes(rank)) {
                return {
                  ...compilation,
                  apps: [],
                  background: compilationBannerRef,
                };
              }

              const apps = await appAPI.listApps({
                ...DEFAULT_PAGE_PARAMS,
                compilationId: id,
              });

              return {
                ...compilation,
                apps,
                background: iconRef,
              };
            }),
        );

        const compilationsByTypeWithApps = compilationsWithApps.reduce(
          (acc, compilation) => {
            if (BANNER_RANK_VALUES.includes(compilation.rank)) {
              acc.banner.push(compilation);
              return acc;
            }

            acc.usual.push(compilation);
            return acc;
          },
          { banner: [], usual: [] } as CompilationsByType,
        );

        setCompilationsFeed((prev) => ({
          banner: [...prev.banner, ...compilationsByTypeWithApps.banner],
          usual: [...prev.usual, ...compilationsByTypeWithApps.usual],
        }));
      } catch (error) {
        console.error(error);
      } finally {
        setIsAppsLoading(false);
      }
    };

    fetchAppsByCompilations();
  }, [compilations, isCompilationsEmpty, isCompilationsLoading, page]);

  return {
    compilationsFeed,
    isFeedLoading: isAppsLoading,
    isFeedEmpty:
      !compilationsFeed.banner.length && !compilationsFeed.usual.length,
  };
};

export default useCompilationsFeed;
