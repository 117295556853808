import { Box, Typography } from "@mui/material";
import { CardCategory, CategoryCardProps } from "../UI/CardCategory";

import classes from "./CategoriesCardsContainer.module.scss";

export interface CategoriesCardsContainerProps {
  categories: Array<CategoryCardProps>;
}

function CategoriesCardsContainer({
  categories,
}: CategoriesCardsContainerProps) {
  const filteredCategories = categories.filter((cat) => cat.count);

  return (
    <Box className={classes.root}>
      <Typography variant="h1">Категории</Typography>
      {filteredCategories.length ? (
        <Box className={classes.container}>
          {filteredCategories.map(({ id, name, count, iconRef }) => (
            <CardCategory
              key={id}
              id={id}
              name={name}
              count={count}
              iconRef={iconRef}
            />
          ))}
        </Box>
      ) : (
        <Typography variant="h2">Не найдено ни одной категории</Typography>
      )}
    </Box>
  );
}

export default CategoriesCardsContainer;
