import { useEffect, useState } from "react";

import { categoriesSelector } from "@store/selectors/categories";
import { CategoriesWithApps } from "@interfaces/api";
import { DEFAULT_PAGE_PARAMS } from "@constants/common";

import appAPI from "@api/application";

const RECOMENDED_CHUNK_SIZE = 6;

const useCategoriesWithApps = (page: number) => {
  const { categories, isCategoriesLoading, isCategoriesEmpty } =
    categoriesSelector();

  const [categoriesWithApps, setCategoriesWithApps] =
    useState<CategoriesWithApps>([]);
  const [isAppsLoading, setIsAppsLoading] = useState(true);

  useEffect(() => {
    if (isCategoriesLoading) {
      return;
    }

    if (isCategoriesEmpty) {
      setIsAppsLoading(false);
      return;
    }

    const fetchAppsByCategories = async () => {
      try {
        const fetchedCategoriesWithApps = await Promise.all(
          categories
            .slice(
              RECOMENDED_CHUNK_SIZE * (page - 1),
              RECOMENDED_CHUNK_SIZE * page,
            )
            .map(async ({ id, name }) => {
              const apps = await appAPI.listApps({
                ...DEFAULT_PAGE_PARAMS,
                categoryId: id,
              });

              return { id, name, apps };
            }),
        ).then((data) => data.filter((cat) => cat.apps.length));

        setCategoriesWithApps((prev) => [
          ...prev,
          ...fetchedCategoriesWithApps,
        ]);
      } catch (error) {
        console.error(error);
      } finally {
        setIsAppsLoading(false);
      }
    };

    fetchAppsByCategories();
  }, [categories, isCategoriesEmpty, isCategoriesLoading, page]);

  return {
    categoriesWithApps,
    isCategoriesLoading: isAppsLoading,
    isCategoriesEmpty: !categoriesWithApps.length,
  };
};

export default useCategoriesWithApps;
