type AddFieldToAllItems = (params: {
  array: Array<any>;
  field: string;
  value: any;
}) => any[];

export const addFieldToAllItems: AddFieldToAllItems = ({
  array,
  field,
  value,
}) => array.map((item) => ({ ...item, [field]: value }));

type SortByField = (params: { array: Array<any>; field: string }) => any[];

export const sortByField: SortByField = ({ array, field }) =>
  array.sort((a, b) => a[field] - b[field]);

type GetSafeArray = (...args: any[]) => any[];

export const getSafeArray: GetSafeArray = (...args) => args.filter(Boolean);
