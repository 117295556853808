/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { CompilationFilterField, CompilationsArrayType } from "@interfaces/api";
import { FetchStatuses, FilterMethods } from "@interfaces/common";

import compilationsAPI from "@api/compilation";

type CompilationsState = {
  status: FetchStatuses | null;
  error: string | null;
  items: CompilationsArrayType;
};

const initialState: CompilationsState = {
  status: null,
  error: null,
  items: [],
};

export const fetchCompilations = createAsyncThunk(
  "compilations/fetchCompilations",
  async () =>
    compilationsAPI.listCompilations(
      `${CompilationFilterField.RANK} ${FilterMethods.ASC}`,
    ),
);

const CompilationsSlice = createSlice({
  name: "compilations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCompilations.pending, (state) => {
      state.status = FetchStatuses.PENDING;
      state.error = null;
    });
    builder.addCase(
      fetchCompilations.fulfilled,
      (state, action: PayloadAction<CompilationsArrayType>) => {
        state.error = null;
        state.items = action.payload;
        state.status = FetchStatuses.RESOLVED;
      },
    );
    builder.addCase(fetchCompilations.rejected, (state) => {
      state.status = FetchStatuses.REJECTED;
      state.error = "Error while fetch compilations";
    });
  },
});

export default CompilationsSlice.reducer;
