import { Box, Typography } from "@mui/material";
import classes from "./ApplicationInfo.module.scss";

export interface ApplicationInfoItem {
  label: string | number;
  value: string | number;
  onClick?: () => void;
}

export interface ApplicationInfoProps {
  rows?: number;
  columns: number;
  appInfo: Array<ApplicationInfoItem>;
}

function ApplicationInfo({ appInfo, rows = 1, columns }: ApplicationInfoProps) {
  const rowsCounter = Array.from(Array(rows).keys());

  return (
    <Box className={classes.root}>
      {rowsCounter.map((rowIndex) => (
        <Box key={rowIndex} className={classes.row}>
          {appInfo
            .slice(rowIndex * columns, columns + rowIndex * columns)
            .map(({ label, value, onClick }) => (
              <Box key={label} className={classes.appInfoWrapper}>
                <Typography variant="body2" color="#53546e">
                  {label}
                </Typography>
                <Typography
                  variant="body2"
                  onClick={onClick}
                  className={onClick && classes.link}
                >
                  {value}
                </Typography>
              </Box>
            ))}
        </Box>
      ))}
    </Box>
  );
}

export default ApplicationInfo;
