/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState, useMemo } from "react";
import ItemsCarousel from "react-items-carousel";
import useWindowDimensions from "@hooks/useWindowDimensions";

import { Box, Modal } from "@mui/material";
import CarouselButton from "@components/UI/CarouselButton";

import { getFullUrl, getThumbnailUrl } from "@utils/url.helpers";

import {
  CAROULSEL_CONFIG,
  WINDOW_GAP_RATIO,
  RECOMENDED_ITEM_WIDTH,
} from "./constants";

import classes from "./Carousel.module.scss";

interface CarouselProps {
  images: string[];
}

function Carousel({ images }: CarouselProps) {
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  const [lightboxImage, setLightboxImage] = useState<string>("");
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0);
  const [containerWidth, setContainerWidth] = useState<number>(windowWidth);

  const numberOfCards = useMemo(
    () =>
      Math.ceil(
        (containerWidth - CAROULSEL_CONFIG.CHEVRON_WIDTH) /
          (RECOMENDED_ITEM_WIDTH + CAROULSEL_CONFIG.GUTTER),
      ),
    [containerWidth],
  );

  const toggleImageLightbox = (e: any) => {
    const { image } = e.currentTarget.dataset;
    setLightboxImage(image);
  };

  const getPreviewStyles = (previewNode: HTMLImageElement) => {
    if (!previewNode) return {};

    const { width, height } = previewNode.getBoundingClientRect();
    const ratio = Math.min(windowWidth / width, windowHeight / height);

    return {
      imageWidth: width * ratio * WINDOW_GAP_RATIO,
      imageHeight: height * ratio * WINDOW_GAP_RATIO,
    };
  };

  const handleSetContainerRef = (node: HTMLImageElement) => {
    if (node) {
      const { width } = node.getBoundingClientRect();
      setContainerWidth(width);
    }
  };

  const handleSetFullscreenRef = (node: HTMLImageElement) => {
    if (node) {
      node.onload = () => {
        const { imageWidth, imageHeight } = getPreviewStyles(node);
        node.style.width = `${imageWidth}px`;
        node.style.height = `${imageHeight}px`;
        node.style.visibility = "visible";
      };
    }
  };

  return (
    <Box ref={handleSetContainerRef}>
      <ItemsCarousel
        numberOfCards={numberOfCards}
        gutter={CAROULSEL_CONFIG.GUTTER}
        activeItemIndex={activeImageIndex}
        requestToChangeActive={setActiveImageIndex}
        chevronWidth={CAROULSEL_CONFIG.CHEVRON_WIDTH}
        leftChevron={<CarouselButton direction="left" />}
        rightChevron={<CarouselButton direction="right" />}
        classes={{ itemWrapper: classes.screenshotWrapper }}
      >
        {images.map((image) => (
          <img
            key={image}
            alt="preview"
            data-image={image}
            src={getThumbnailUrl(image)}
            onClick={toggleImageLightbox}
            className={classes.screenshot}
          />
        ))}
      </ItemsCarousel>

      <Modal
        open={!!lightboxImage}
        className={classes.lightbox}
        onClose={toggleImageLightbox}
      >
        <img
          alt="fullscreen-preview"
          ref={handleSetFullscreenRef}
          src={getFullUrl(lightboxImage)}
        />
      </Modal>
    </Box>
  );
}

export default Carousel;
