import { useState } from "react";
import useObserver from "@hooks/useObserver";
import useFetchByPage from "@hooks/useFetchByPage";
import { useNavigate, useParams } from "react-router-dom";

import { Box, IconButton, Typography } from "@mui/material";
// import Select, { OptionClickParams } from "@components/UI/Select";
import { ArrowBackIcon } from "@assets/icons";
import MiniCardApp from "@components/UI/MiniCardApp/MiniCardApp";
import LoadingContainer from "@components/LoadingContainer";

import compilationAPI from "@api/compilation";
import appAPI from "@api/application";
import useFetch from "@hooks/useFetch";

import {
  PagedApps,
  ListPagedAppsFuncParams,
  Compilation,
} from "@interfaces/api";
import { PAGED_APPS_INITIAL, FILTER_INITIAL } from "./constants";

import classes from "./DetailedCompilationPage.module.scss";

function DetailedCompilationPage() {
  const { id = 0 } = useParams();
  const navigate = useNavigate();

  const [compilation] = useFetch<Compilation | null>({
    cb: compilationAPI.getCompilation,
    params: { id },
    initial: null,
  });
  const [filter, setFilter] = useState<ListPagedAppsFuncParams>({
    ...FILTER_INITIAL,
    compilationId: +id,
  });
  const [pagedApps, _setPagedApps, hasMore, isLoading, error] = useFetchByPage<
    PagedApps,
    ListPagedAppsFuncParams
  >({
    cb: appAPI.listPagedApps,
    initial: PAGED_APPS_INITIAL,
    params: filter,
    deps: [filter],
  });
  const [refCallback] = useObserver({
    isLoading,
    hasMore,
    cb: () => setFilter((prev) => ({ ...prev, page: prev.page + 1 })),
  });

  const goBack = () => navigate(-1);

  // const handleFilterChange = ({ name, value }: OptionClickParams) => {
  //   setPagedApps(PAGED_APPS_INITIAL);
  //   setFilter((prev) => ({ ...prev, [name]: value }));
  // };

  const isFirstFetching = isLoading && !pagedApps.elements.length;

  if (error) {
    return (
      <Box className={classes.root}>
        <Typography variant="h1">Список приложений недоступен!</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <IconButton onClick={goBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h1">{compilation?.name}</Typography>
        {/* <Select
          value={filter.sortBy}
          valueField="value"
          labelField="label"
          fieldToChange="sortBy"
          options={SORT_FILTER_OPTIONS}
          onChange={handleFilterChange}
          className={classes.select}
        /> */}
      </Box>

      <LoadingContainer isFirstFetching={isFirstFetching} isLoading={isLoading}>
        <Box className={classes.content}>
          {pagedApps.elements.map((app, i) => {
            const ref =
              pagedApps.elements.length === i + 1 ? refCallback : null;
            return <MiniCardApp ref={ref} key={app.id} app={app} />;
          })}
        </Box>
      </LoadingContainer>
    </Box>
  );
}

export default DetailedCompilationPage;
