import { FilterMethods } from "@interfaces/common";
import {
  PagedApps,
  AppFilterField,
  CompilationAppsArrayType,
  ListPagedAppsFuncParams,
} from "@interfaces/api";

export const COMPILATION_APPS_INITIAL: CompilationAppsArrayType = [];

export const PAGED_APPS_INITIAL: PagedApps = {
  pageMeta: {
    currentPage: 1,
    pageSize: 0,
    pageItems: 0,
    totalPages: 0,
    totalCount: 0,
  },
  elements: [],
};

export const FILTER_INITIAL: ListPagedAppsFuncParams = {
  page: 1,
  perPage: 10,
  sortBy: `${AppFilterField.RATING} ${FilterMethods.DESC}`,
};

export const SORT_FILTER_OPTIONS = [
  {
    id: 2,
    value: `${AppFilterField.RATING} ${FilterMethods.DESC}`,
    label: "Сортировать по популярности",
  },
  {
    id: 3,
    value: `${AppFilterField.DOWNLOADS} ${FilterMethods.DESC}`,
    label: "Сортировать по количеству скачиваний",
  },
];
