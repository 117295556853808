import { getThumbnailUrl } from "./url.helpers";

type PluralType = (form: Array<string>, n: number) => void;

export const plural: PluralType = (forms, n) => {
  let idx;
  if (n % 10 === 1 && n % 100 !== 11) {
    idx = 0; // many
  } else if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
    idx = 1; // few
  } else {
    idx = 2; // one
  }
  return forms[idx] || "";
};

type PathToCssUrlType = (path: string | undefined) => string | undefined;
export const pathToCssUrl: PathToCssUrlType = (path) =>
  path ? `url(${getThumbnailUrl(path)})` : undefined;

type IsUndefinedType = (value: any) => boolean;

export const isEmpty: IsUndefinedType = (value) =>
  value === "undefined" ||
  value === undefined ||
  value === null ||
  value === "";
