import { useState } from "react";
import { useLocation } from "react-router-dom";
import useObserver from "@hooks/useObserver";
import useFetchByPage from "@hooks/useFetchByPage";

import { categoriesSelector } from "@selectors/categories";
import { compilationsSelector } from "@selectors/compilations";

import { Box, Typography } from "@mui/material";
import Select, { OptionClickParams } from "@components/UI/Select";
import CardApp from "@components/UI/CardApp";
import LoadingContainer from "@components/LoadingContainer/LoadingContainer";

import { PagedApps, ListPagedAppsFuncParams } from "@interfaces/api";
import { plural } from "@utils/string.helpers";
import { removeFieldsFromObject } from "@utils/object.helpers";

import appAPI from "@api/application";
import {
  PAGED_APPS_INITIAL,
  PAGE_QUERY_INITIAL,
  SORT_FILTER_OPTIONS,
} from "./constants";

import classes from "./AllApplicationsPage.module.scss";

function AllApplicationsPage() {
  const { state } = useLocation();
  const { categories } = categoriesSelector();
  const { compilations } = compilationsSelector();

  const [query, setQuery] = useState<ListPagedAppsFuncParams>({
    ...PAGE_QUERY_INITIAL,
    ...(state?.sortBy && { sortBy: state.sortBy }),
  });
  const [pagedApps, setPagedApps, hasMore, isLoading, error] = useFetchByPage<
    PagedApps,
    ListPagedAppsFuncParams
  >({
    cb: appAPI.listPagedApps,
    initial: PAGED_APPS_INITIAL,
    params: query,
    deps: [query],
  });

  const [refCallback] = useObserver({
    isLoading,
    hasMore,
    cb: () => setQuery((prev) => ({ ...prev, page: prev.page + 1 })),
  });

  const handleSelectChange = ({ name, value }: OptionClickParams) => {
    setPagedApps(PAGED_APPS_INITIAL);

    if (!value) {
      setQuery((prev) => ({
        ...(removeFieldsFromObject(prev, [name]) as ListPagedAppsFuncParams),
        page: 1,
      }));
      return;
    }

    setQuery((prev) => ({ ...prev, page: 1, [name]: value }));
  };

  const isFirstFetching = isLoading && !pagedApps.elements.length;

  if (error) {
    return (
      <Box className={classes.root}>
        <Typography variant="h1">Список приложений недоступен!</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Typography variant="h1">Все приложения</Typography>

      <Box className={classes.header}>
        <Typography variant="h2">
          {`${pagedApps.pageMeta.totalCount} ${plural(
            ["приложение", "приложения", "приложений"],
            pagedApps.pageMeta.totalCount,
          )}`}
        </Typography>

        <Box className={classes.headerContainer}>
          <Select
            value={query.compilationId}
            valueField="id"
            labelField="name"
            options={compilations}
            defaultLabel="Все подборки"
            fieldToChange="compilationId"
            isLoading={isLoading}
            onChange={handleSelectChange}
          />
          <Select
            value={query.categoryId}
            valueField="id"
            labelField="name"
            options={categories}
            defaultLabel="Все категории"
            fieldToChange="categoryId"
            isLoading={isLoading}
            onChange={handleSelectChange}
          />
          <Select
            value={query.sortBy}
            valueField="value"
            labelField="label"
            fieldToChange="sortBy"
            isLoading={isLoading}
            options={SORT_FILTER_OPTIONS}
            onChange={handleSelectChange}
          />
        </Box>
      </Box>

      <LoadingContainer isFirstFetching={isFirstFetching} isLoading={isLoading}>
        {!pagedApps.elements.length && !isFirstFetching && (
          <Typography variant="h1">Не найдено ни одного приложения</Typography>
        )}

        <Box className={classes.contentWrapper}>
          {pagedApps.elements.map((app, i) => (
            <CardApp
              key={app.id}
              app={app}
              ref={pagedApps.elements.length === i + 1 ? refCallback : null}
            />
          ))}
        </Box>
      </LoadingContainer>
    </Box>
  );
}

export default AllApplicationsPage;
