import appstoreAPI from "@utils/axios";
import {
  ListCompilationsFuncType,
  GetCompilationFuncType,
  ListPagedCompilationsFuncType,
} from "@interfaces/api";

const getCompilation: GetCompilationFuncType = async ({ id }) => {
  const response = await appstoreAPI.get(`compilations/${id}`);
  return response.data;
};

const listCompilations: ListCompilationsFuncType = async (sortBy) => {
  const response = await appstoreAPI.request({
    url: "compilations",
    method: "get",
    params: { sortBy },
  });

  return response.data;
};

const listPagedCompilations: ListPagedCompilationsFuncType = async (query) => {
  const response = await appstoreAPI.request({
    url: "compilations/paged",
    method: "get",
    params: { ...query },
  });

  return response.data;
};

export default {
  listCompilations,
  getCompilation,
  listPagedCompilations,
};
