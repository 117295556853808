import { RouterProvider, createHashRouter } from "react-router-dom";
import { withProviders } from "./providers";
import { ROUTER_CONFIG } from "./pages";

const router = createHashRouter(ROUTER_CONFIG);

function App() {
  return <RouterProvider router={router} />;
}

export default withProviders(App);
