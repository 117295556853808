import { useState } from "react";
import useObserver from "@hooks/useObserver";
import useCategoriesWithApps from "@hooks/useCategoriesWithApps";

import { categoriesSelector } from "@selectors/categories";

import { Box } from "@mui/material";
import LoadingContainer from "@components/LoadingContainer";
import MiniCardsContainer from "@components/MiniCardsContainer";
import CategoriesCardsContainer from "@components/CategoriesCardsContainer";

import classes from "./CategoriesPage.module.scss";

function CategoriesPage() {
  const [page, setPage] = useState(1);

  const { categories } = categoriesSelector();
  const { categoriesWithApps, isCategoriesLoading } =
    useCategoriesWithApps(page);

  const [handleSetLastElementRef] = useObserver({
    cb: () => setPage((prevPage) => prevPage + 1),
    isLoading: isCategoriesLoading,
    hasMore: categoriesWithApps.length < categories.length,
  });

  return (
    <LoadingContainer isLoading={isCategoriesLoading}>
      <Box className={classes.root}>
        <CategoriesCardsContainer categories={categories} />

        {categoriesWithApps.map(({ id, name, apps }, i, arr) =>
          arr.length === i + 1 ? (
            <MiniCardsContainer
              key={id}
              id={id}
              title={name}
              cards={apps}
              ref={handleSetLastElementRef}
            />
          ) : (
            <MiniCardsContainer key={id} id={id} title={name} cards={apps} />
          ),
        )}
      </Box>
    </LoadingContainer>
  );
}

export default CategoriesPage;
