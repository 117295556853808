export const FEED_RANK_SEQUENCE = {
  FIRST_BANNER: 1,
  SECOND_BANNER: 2,
  FIRST_CARD: 3,
  SECOND_CARD: 4,
};

export const ALLOWED_RANK_VALUES = Object.values(FEED_RANK_SEQUENCE);

export const BANNER_RANK_VALUES = [
  FEED_RANK_SEQUENCE.FIRST_BANNER,
  FEED_RANK_SEQUENCE.SECOND_BANNER,
];

export const CARD_RANK_VALUES = [
  FEED_RANK_SEQUENCE.FIRST_CARD,
  FEED_RANK_SEQUENCE.SECOND_CARD,
];
