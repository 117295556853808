type RemoveFieldsFromObjectType = (
  obj: { [name: string]: any },
  fieldsToRemove: string[],
) => { [name: string]: any };

export const removeFieldsFromObject: RemoveFieldsFromObjectType = (
  object,
  fieldsToRemove,
) =>
  Object.fromEntries(
    Object.entries(object).filter(([name]) => !fieldsToRemove.includes(name)),
  );
