import { ReactNode } from "react";
import { Link, useMatch } from "react-router-dom";
import cn from "classnames";

import classes from "./CustomNavLink.module.scss";

export interface CustomNavLinkProps {
  to: string;
  className?: string;
  disabled?: boolean;
  children: ReactNode;
  activeClassName?: string;
  target?: string;
  onClick?: (url: string) => void;
}

function CustomNavLink(props: CustomNavLinkProps) {
  const {
    to,
    disabled,
    children,
    className,
    activeClassName,
    target,
    onClick,
  } = props;
  const match = useMatch(to);
  const allClasses = cn(
    className,
    { [activeClassName || classes.active]: match },
    { [classes.disabled]: disabled },
  );

  return onClick ? (
    <div onClick={() => onClick(to)} className={allClasses}>
      {children}
    </div>
  ) : (
    <Link to={to} className={allClasses} target={target}>
      {children}
    </Link>
  );
}

export default CustomNavLink;
