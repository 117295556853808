import { RouteObject } from "react-router-dom";

import Layout from "@components/Layout";
import { PAGES } from "../shared/constants";

import Home from "./Home";
import Error from "./ErrorPage";
import Search from "./SearchingResultPage";
import Installed from "./MyApplicationsPage";
import Categories from "./CategoriesPage";
import Application from "./ReviewApplicationPage";
import Compilations from "./CompilationsPage";
import Applications from "./AllApplicationsPage";
import CategoryDetails from "./DetailedCategoryPage";
import CompilationDetails from "./DetailedCompilationPage";

export const ROUTER_CONFIG: RouteObject[] = [
  {
    path: "/",
    element: <Layout />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        path: PAGES.HOME,
        element: <Home />,
      },
      {
        path: PAGES.CATEGORIES,
        element: <Categories />,
      },
      {
        path: PAGES.COMPILATIONS,
        element: <Compilations />,
      },
      {
        path: PAGES.APPLICATIONS,
        element: <Applications />,
      },
      {
        path: PAGES.CATEGORY_DETAILS,
        element: <CategoryDetails />,
      },
      {
        path: PAGES.COMPILATION_DETAILS,
        element: <CompilationDetails />,
      },
      {
        path: PAGES.APPLICATION,
        element: <Application />,
      },
      {
        path: PAGES.INSTALLED,
        element: <Installed />,
      },
      {
        path: PAGES.SEARCH,
        element: <Search />,
      },
      {
        path: PAGES.ERROR,
        element: <Error />,
      },
    ],
  },
];
