/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { ShortInfoApp } from "@interfaces/api";
import { FetchStatuses } from "@interfaces/common";

import appsAPI from "@api/application";

type AppsState = {
  status: FetchStatuses | null;
  error: string | null;
  items: ShortInfoApp[];
};

const initialState: AppsState = {
  status: null,
  error: null,
  items: [],
};

export const fetchApps = createAsyncThunk("apps/fetchApps", async () => {
  const response = await appsAPI.listPagedApps({ page: 1, perPage: -1 });
  return response.elements;
});

const AppsSlice = createSlice({
  name: "apps",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchApps.pending, (state) => {
      state.status = FetchStatuses.PENDING;
      state.error = null;
    });
    builder.addCase(
      fetchApps.fulfilled,
      (state, action: PayloadAction<ShortInfoApp[]>) => {
        state.error = null;
        state.items = action.payload;
        state.status = FetchStatuses.RESOLVED;
      },
    );
    builder.addCase(fetchApps.rejected, (state) => {
      state.status = FetchStatuses.REJECTED;
      state.error = "Error while fetch apps";
    });
  },
});

export default AppsSlice.reducer;
