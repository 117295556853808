export const PAGES = {
  HOME: "/",
  CATEGORIES: "/categories",
  COMPILATIONS: "/compilations",
  APPLICATIONS: "/applications",
  CATEGORY_DETAILS: "/categories/:id",
  COMPILATION_DETAILS: "/compilations/:id",
  APPLICATION: "/applications/:id",
  INSTALLED: "/installed",
  SEARCH: "/search",
  ERROR: "*",
} as const;
