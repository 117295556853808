import { FilterMethods } from "@interfaces/common";
import {
  PagedApps,
  AppsArrayType,
  AppFilterField,
  CategoriesArrayType,
  ListPagedAppsFuncParams,
} from "@interfaces/api";

export const RECOMENDATION_INITIAL: AppsArrayType = [];
export const CATEGORIES_INITIAL: CategoriesArrayType = [];

export const PAGED_APPS_INITIAL: PagedApps = {
  pageMeta: {
    currentPage: 1,
    pageSize: 0,
    pageItems: 0,
    totalPages: 0,
    totalCount: 0,
  },
  elements: [],
};

export const PAGE_QUERY_INITIAL: ListPagedAppsFuncParams = {
  page: 1,
  perPage: -1,
  sortBy: `${AppFilterField.TITLE} ${FilterMethods.ASC}`,
};

export const SORT_FILTER_OPTIONS = [
  {
    id: 1,
    value: `${AppFilterField.TITLE} ${FilterMethods.ASC}`,
    label: "По наименованию (А-Я)",
  },
  {
    id: 2,
    value: `${AppFilterField.TITLE} ${FilterMethods.DESC}`,
    label: "По наименованию (Я-А)",
  },
  // {
  //   id: 2,
  //   value: `${AppFilterField.RATING} ${FilterMethods.DESC}`,
  //   label: "Сортировать по популярности",
  // },
  // {
  //   id: 3,
  //   value: `${AppFilterField.DOWNLOADS} ${FilterMethods.DESC}`,
  //   label: "Сортировать по количеству скачиваний",
  // },
];
