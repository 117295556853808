/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

import { PAGES } from "@shared/constants";
import { Box } from "@mui/material";
import { SearchIcon, InputSendIcon } from "@assets/icons";

import classes from "./SidebarInput.module.scss";

function SidebarInput() {
  const navigate = useNavigate();
  const [query, setQuery] = useState<string>("");
  const [isInFocus, setIsInFocus] = useState<boolean>(false);

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleInputStatusChage = () => {
    setIsInFocus((prev) => !prev);
  };

  const handleSendQueryClick = () => {
    navigate(PAGES.SEARCH, { state: { searchString: query } });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code !== "Enter" || !query) {
      return;
    }

    handleSendQueryClick();
  };

  return (
    <Box
      tabIndex={0}
      onFocus={handleInputStatusChage}
      onBlur={handleInputStatusChage}
      className={cn(classes.root, { [classes.active]: isInFocus })}
    >
      <Box className={classes.wrapper}>
        <Box className={cn(classes.iconWrapper, classes.searchIcon)}>
          <SearchIcon />
        </Box>
        <input
          type="text"
          value={query}
          onKeyDown={handleKeyDown}
          onChange={handleQueryChange}
          className={classes.searchInput}
          placeholder={isInFocus ? undefined : "Поиск"}
        />
        <Box
          onClick={handleSendQueryClick}
          className={cn(classes.iconWrapper, classes.sendIcon, {
            [classes.disable]: !query,
          })}
        >
          <InputSendIcon />
        </Box>
      </Box>
    </Box>
  );
}
export default SidebarInput;
