import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useFetch from "@hooks/useFetch";

import { deviceSelector } from "@selectors/device";

import { Box, Typography } from "@mui/material";
import Section from "@components/UI/Section";
import Carousel from "@components/UI/Carousel";
import LoadingContainer from "@components/LoadingContainer";

import appAPI from "@api/application";
import { App } from "@interfaces/api";

import { APP_INITIAL } from "./constants";

import ApplicationInfo from "./components/ApplicationInfo";
import ApplicationHeader from "./components/ApplicationHeader";

import classes from "./ReviewApplicationPage.module.scss";
import { getInfoData } from "./helpers";

function ReviewApplicationPage() {
  const { id } = useParams();
  const { installedApps } = deviceSelector();

  const [app, setApp, isAppLoading, error] = useFetch<App>({
    cb: appAPI.getApp,
    initial: APP_INITIAL,
    params: { id },
    deps: [id],
  });

  const infoData = getInfoData(app);

  useEffect(() => {
    if (isAppLoading) {
      return;
    }

    setApp((prev) => ({
      ...prev,
      isInstaled: installedApps.has(prev.packageName),
    }));
  }, [installedApps, isAppLoading, setApp]);

  if (error) {
    return (
      <Box className={classes.root}>
        <Typography variant="h1">Приложение недоступно!</Typography>
      </Box>
    );
  }

  return (
    <LoadingContainer isLoading={isAppLoading}>
      <Box className={classes.root}>
        <ApplicationHeader app={app} />

        {app.screenshotRefs.length ? (
          <Section title="Снимки экрана">
            <Carousel images={app.screenshotRefs} />
          </Section>
        ) : null}

        {app.description ? (
          <Section title="Описание">
            <Typography variant="body2">{app.description}</Typography>
          </Section>
        ) : null}

        <Section title="Информация">
          <ApplicationInfo appInfo={infoData} columns={infoData.length} />
        </Section>
      </Box>
    </LoadingContainer>
  );
}

export default ReviewApplicationPage;
