import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useFetch from "@hooks/useFetch";

import { Box, IconButton, Typography } from "@mui/material";
import Select, { OptionClickParams } from "@components/UI/Select";
import MiniCardApp from "@components/UI/MiniCardApp";
import LoadingContainer from "@components/LoadingContainer";
import { ArrowBackIcon } from "@assets/icons";

import appAPI from "@api/application";
import categoryAPI from "@api/category";

import { plural } from "@utils/string.helpers";
import { removeFieldsFromObject } from "@utils/object.helpers";
import {
  PagedApps,
  ListPagedAppsFuncParams,
  CategoriesArrayType,
} from "@interfaces/api";
import {
  PAGED_APPS_INITIAL,
  PAGE_QUERY_INITIAL,
  CATEGORIES_INITIAL,
  SORT_FILTER_OPTIONS,
} from "./constants";

import classes from "./SearchingResultPage.module.scss";

function SearchingResultPage() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [query, setQuery] = useState<ListPagedAppsFuncParams>({
    ...PAGE_QUERY_INITIAL,
    searchString: state.searchString,
  });

  const [categories] = useFetch<CategoriesArrayType>({
    cb: categoryAPI.listCategories,
    initial: CATEGORIES_INITIAL,
  });

  const [pagedApps, _setPagedApps, isLoading, error] = useFetch<
    PagedApps,
    ListPagedAppsFuncParams
  >({
    cb: appAPI.listPagedApps,
    initial: PAGED_APPS_INITIAL,
    params: query,
    deps: [query],
  });

  const handleSelectChange = ({ name, value }: OptionClickParams) => {
    if (!value) {
      setQuery((prev) => ({
        ...(removeFieldsFromObject(prev, [name]) as ListPagedAppsFuncParams),
        page: 1,
      }));
      return;
    }

    setQuery((prev) => ({ ...prev, [name]: value }));
  };

  const handleGoBack = () => navigate(-1);

  useEffect(() => {
    if (query.searchString === state.searchString) {
      return;
    }

    setQuery((prev) => ({ ...prev, searchString: state.searchString }));
  }, [query, state]);

  if (error) {
    return (
      <Box className={classes.root}>
        <Typography variant="h1">Ошибка во время поиска!</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <IconButton onClick={handleGoBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h1">{`Результаты поиска ${state.searchString}`}</Typography>
      </Box>

      <Box className={classes.filters}>
        <Typography variant="h2">
          {`${pagedApps.pageMeta.pageItems} ${plural(
            ["результат", "результата", "результатов"],
            pagedApps.pageMeta.pageItems,
          )}`}
        </Typography>

        <Box className={classes.filtersWrapper}>
          <Select
            value={query.categoryId}
            valueField="id"
            labelField="name"
            options={categories}
            defaultLabel="Все категории"
            fieldToChange="categoryId"
            isLoading={isLoading}
            onChange={handleSelectChange}
          />
          <Select
            value={query.sortBy}
            valueField="value"
            labelField="label"
            fieldToChange="sortBy"
            options={SORT_FILTER_OPTIONS}
            isLoading={isLoading}
            onChange={handleSelectChange}
          />
        </Box>
      </Box>

      <LoadingContainer isLoading={isLoading}>
        <Box className={classes.contentWrapper}>
          {pagedApps.elements.map((app) => (
            <MiniCardApp key={app.id} app={app} />
          ))}
        </Box>
      </LoadingContainer>
    </Box>
  );
}

export default SearchingResultPage;
