import { forwardRef } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import { ShortInfoApp } from "@interfaces/api";
import { pathToCssUrl } from "@utils/string.helpers";

import { PAGES } from "@shared/constants";
import { Button, Typography, Box } from "@mui/material";
import CardApp from "../UI/CardApp";

import classes from "./CardsContainer.module.scss";

export interface CardsContainerProps {
  id: number;
  title: string;
  description: string;
  cards: ShortInfoApp[];
  background?: string;
  className?: string;
}

const CardsContainer = forwardRef<HTMLDivElement, CardsContainerProps>(
  ({ id, title, description, cards, className, background = "" }, ref) => {
    const linkState = { compilationName: title, background };
    const cardBgStyle = { backgroundImage: pathToCssUrl(background) };

    return (
      <Box
        ref={ref}
        style={cardBgStyle}
        className={cn(classes.root, className)}
      >
        <Box className={classes.headerContainer}>
          <Box className={classes.headerInfo}>
            <Typography color="white" variant="h2">
              {title}
            </Typography>
            <Typography color="white" variant="body2">
              {description}
            </Typography>
          </Box>
          <Button
            component={Link}
            state={linkState}
            variant="outlined"
            to={`${PAGES.COMPILATIONS}/${id}`}
            className={classes.watchAllButton}
          >
            См. все
          </Button>
        </Box>

        <Box className={classes.cardsContainer}>
          {cards.map((app) => (
            <CardApp key={app.id} app={app} />
          ))}
        </Box>
      </Box>
    );
  },
);

export default CardsContainer;
