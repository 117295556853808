import { Box } from "@mui/material";

import { useSidebarConfig } from "@schemas/sidebar";

import SidebarInput from "./SidebarInput";
import SidebarElement from "./SidebarElement";

import classes from "./Sidebar.module.scss";

function Sidebar() {
  const config = useSidebarConfig();

  return (
    <Box className={classes.root}>
      <SidebarInput />

      <Box className={classes.wrapper}>
        {config.map(
          ({ id, withWrapper, wrapperClassName, listClassName, elements }) =>
            withWrapper ? (
              <Box key={id} className={wrapperClassName}>
                <ul className={listClassName}>
                  {elements.map((item) => (
                    <SidebarElement key={item.name} {...item} />
                  ))}
                </ul>
              </Box>
            ) : (
              <ul key={id} className={listClassName}>
                {elements.map((item) => (
                  <SidebarElement key={item.name} {...item} />
                ))}
              </ul>
            ),
        )}
      </Box>
    </Box>
  );
}

export default Sidebar;
