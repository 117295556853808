import appstoreAPI from "@utils/axios";
import {
  GetAppFuncType,
  ListAppsFuncType,
  ListPagedAppsFuncType,
  ListAppByCategoryFuncType,
  ListAppsByCompilationFuncType,
} from "@interfaces/api";

const getApp: GetAppFuncType = async ({ id }) => {
  const response = await appstoreAPI.get(`apps/${id}`);
  return response.data;
};

const listPagedApps: ListPagedAppsFuncType = async (query) => {
  const response = await appstoreAPI.request({
    url: "apps/paged",
    method: "get",
    params: { ...query },
  });

  return response.data;
};

const listApps: ListAppsFuncType = async (query) => {
  const response = await appstoreAPI.request({
    url: "apps/paged",
    method: "get",
    params: { ...query },
  });

  return response.data.elements;
};

const listAppsByCategory: ListAppByCategoryFuncType = async ({ id }) => {
  const response = await appstoreAPI.get(`apps/category/${id}`);
  return response.data;
};

const listAppsByCompilation: ListAppsByCompilationFuncType = async ({ id }) => {
  const response = await appstoreAPI.get(`apps/compilation/${id}`);
  return response.data;
};

export default {
  getApp,
  listApps,
  listPagedApps,
  listAppsByCategory,
  listAppsByCompilation,
};
