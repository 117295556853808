import { FC, useMemo, useState } from "react";
import { useAppDispatch } from "@store/index";
import { deviceSelector } from "@store/selectors/device";
import { installApp, startApp, removeApp } from "@store/DeviceSlice";
import cn from "classnames";

import { ShortInfoApp } from "@interfaces/api";
import { getAppInfo } from "@utils/app.helpers";

import { Button, IconButton, LinearProgress, ButtonProps } from "@mui/material";
import { CartIcon } from "@assets/icons";
import { RemoveAppModal } from "../RemoveAppModal";

import classes from "./AppActionButton.module.scss";

interface AppActionButtonProps
  extends Omit<ButtonProps, "disabled" | "variant"> {
  app: ShortInfoApp;
  mode?: "main" | "deleteIcon";
}

interface CalculatedButtonProps {
  buttonText: string;
  variant?: "text" | "outlined" | "contained";
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
}

export const AppActionButton: FC<AppActionButtonProps> = (props) => {
  const { app, mode = "main", ...rest } = props;
  const { packageName, launchName, packageNameSignature } = app;
  const { installedApps, inProcessingApp, isDeviceReady } = deviceSelector();

  const [showModal, setShowModal] = useState(false);
  const dispatch = useAppDispatch();

  const { isAppInstalled, isAppInProcess, appStatus } = useMemo(
    () =>
      getAppInfo({
        packageName,
        installedApps,
        inProcessingApp,
      }),
    [inProcessingApp, installedApps, packageName],
  );

  const isButtonDisabled =
    !isDeviceReady || isAppInProcess || (isAppInstalled && !launchName);

  const { buttonText, variant, color }: CalculatedButtonProps = useMemo(() => {
    switch (appStatus) {
      case "not-installed":
        return { buttonText: "Установить", variant: "outlined" };
      case "installed":
        return { buttonText: "Открыть", variant: "contained" };
      case "installing":
        return { buttonText: "Установка", variant: "outlined" };
      case "deleting":
        return { buttonText: "Удаление", variant: "contained", color: "error" };
      case "opening":
        return { buttonText: "Запуск", variant: "contained" };
      default:
        return { buttonText: "Ошибка", variant: "contained", color: "error" };
    }
  }, [appStatus]);

  const handleButtonClick = (e: any) => {
    e.stopPropagation();
    if (isAppInstalled) {
      dispatch(startApp(launchName));
    } else {
      dispatch(installApp({ packageName, packageNameSignature }));
    }
  };

  const handleDeleteButtonClick = (e: any) => {
    e.stopPropagation();
    dispatch(removeApp({ packageName, packageNameSignature }));
    setShowModal(false);
  };

  switch (mode) {
    case "deleteIcon":
      return (
        <>
          <IconButton
            disabled={isButtonDisabled}
            className={cn(
              { [classes.deletingIcon]: appStatus === "deleting" },
              rest.className,
            )}
            onClick={() => setShowModal(true)}
          >
            <CartIcon />
          </IconButton>
          <RemoveAppModal
            app={app}
            open={showModal}
            onSubmit={handleDeleteButtonClick}
            onClose={() => setShowModal(false)}
          />
        </>
      );
    default:
      return (
        <Button
          {...rest}
          color={color}
          variant={variant}
          onClick={handleButtonClick}
          disabled={isButtonDisabled}
        >
          {buttonText}
          {appStatus === "installing" ? (
            <LinearProgress className={classes.progress} />
          ) : null}
        </Button>
      );
  }
};
