import { useAppDispatch } from "@store/index";
import { openLinkInBrowser } from "@store/DeviceSlice";
import { SidebarElementProps } from "@components/UI/Sidebar/SidebarElement";
import { deviceSelector } from "@store/selectors/device";

import {
  HomeIcon,
  AllAppsIcon,
  DownloadIcon,
  QuestionIcon,
  CategoriesIcon,
  CompilationsIcon,
} from "@assets/icons";

import classes from "@components/UI/Sidebar/Sidebar.module.scss";
import { PAGES } from "@shared/constants";

export type SidebarConfig = {
  id: number;
  listClassName?: string;
  withWrapper?: boolean;
  wrapperClassName?: string;
  elements: SidebarElementProps[];
}[];

export const useSidebarConfig = (): SidebarConfig => {
  const dispatch = useAppDispatch();
  const { isDevice, isDeviceReady } = deviceSelector();

  return [
    {
      id: 1,
      elements: [
        {
          name: "Обзор",
          icon: <HomeIcon />,
          path: PAGES.HOME,
        },
        {
          name: "Категории",
          icon: <CategoriesIcon />,
          path: PAGES.CATEGORIES,
        },
        {
          name: "Подборки",
          icon: <CompilationsIcon />,
          path: PAGES.COMPILATIONS,
        },
        {
          name: "Все приложения",
          icon: <AllAppsIcon />,
          path: PAGES.APPLICATIONS,
        },
      ],
    },
    {
      id: 2,
      withWrapper: true,
      listClassName: classes.bottomList,
      wrapperClassName: classes.bottomListWrapper,
      elements: [
        {
          name: "Установленные",
          icon: <DownloadIcon />,
          path: PAGES.INSTALLED,
          disabled: !isDeviceReady,
        },
        {
          name: "Помощь",
          icon: <QuestionIcon />,
          path: "https://school.mos.ru/help/instructions/m-os/programs/store/",
          ...(isDevice
            ? { onClick: (url) => dispatch(openLinkInBrowser(url)) }
            : { target: "_blank" }),
        },
      ],
    },
  ];
};
