import { ThemeProvider, createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#228BE6",
    },
    secondary: {
      main: "#4c6ef53d",
    },
    error: {
      main: "#fa5252",
    },
    text: {
      primary: "#101025",
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: "Noto Sans",
    h1: {
      fontSize: 20,
      fontWeight: 800,
      lineHeight: "28px",
    },
    h2: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "24px",
    },
    body1: {
      fontSize: 14,
      lineHeight: "22px",
    },
    body2: {
      fontSize: 12,
      lineHeight: "16px",
    },
    caption: {
      color: "#87879B",
      fontSize: 10,
      lineHeight: "14px",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          width: 94,
          fontSize: 12,
          flexShrink: 0,
          lineHeight: "16px",
          fontWeight: 500,
          borderRadius: 4,
          boxShadow: "none",
          padding: "5px 12px",
          textTransform: "none",
          borderColor: theme.palette.secondary.main,

          ...(ownerState.variant === "outlined" &&
            ownerState.color === "primary" && {
              backgroundColor: theme.palette.common.white,
            }),

          "&:hover": {
            ...(ownerState.variant === "contained" &&
              ownerState.color === "primary" && {
                boxShadow: "none",
              }),
            ...(ownerState.variant === "outlined" &&
              ownerState.color === "primary" && {
                backgroundColor: theme.palette.common.white,
              }),
          },

          "&.Mui-disabled": {
            opacity: 0.5,
            ...(ownerState.variant === "contained" &&
              ownerState.color === "primary" && {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
              }),
            ...(ownerState.variant === "outlined" &&
              ownerState.color === "primary" && {
                color: theme.palette.primary.main,
                borderColor: theme.palette.secondary.main,
                backgroundColor: theme.palette.common.white,
              }),
            ...(ownerState.variant === "contained" &&
              ownerState.color === "error" && {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.error.main,
              }),
          },
        }),
      },
    },
  },
});

export const withTheme = (component: () => React.ReactNode) =>
  function Provider() {
    return <ThemeProvider theme={theme}>{component()}</ThemeProvider>;
  };
