import { InstalledApps } from "@store/DeviceSlice";

type AppStatus =
  | "not-installed"
  | "installed"
  | "installing"
  | "deleting"
  | "opening"
  | "error";

export const getAppInfo = (params: {
  packageName: string;
  installedApps: InstalledApps;
  inProcessingApp: InstalledApps;
}) => {
  const { packageName, installedApps, inProcessingApp } = params;
  const packages = packageName.split(" ");

  let appStatus: AppStatus = "not-installed";
  const isAppInstalled = packages.every((p) => installedApps.has(p));
  const isAppInProcess = packages.every((p) => inProcessingApp.has(p));

  if (isAppInProcess) {
    const mainPackage = packages[0];

    if (mainPackage) {
      switch (inProcessingApp.get(mainPackage)) {
        case "installing":
          appStatus = "installing";
          break;
        case "deleting":
          appStatus = "deleting";
          break;
        case "opening":
          appStatus = "opening";
          break;
        default:
          appStatus = "error";
          break;
      }
    }
  } else {
    appStatus = isAppInstalled ? "installed" : "not-installed";
  }

  return { isAppInstalled, isAppInProcess, appStatus };
};
