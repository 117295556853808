import { ReactNode } from "react";
import cn from "classnames";

import { Box, Typography } from "@mui/material";

import classes from "./Section.module.scss";

export interface SectionProps {
  title: string;
  children: ReactNode;
  className?: string;
}

function Section({ title, children, className }: SectionProps) {
  return (
    <Box className={cn(classes.root, className)}>
      <Typography variant="h2">{title}</Typography>
      {children}
    </Box>
  );
}

export default Section;
