export { default as CartIcon } from "./cart.svg";
export { default as HomeIcon } from "./home.svg";
export { default as BulbIcon } from "./bulb.svg";
export { default as LogoIcon } from "./logo.svg";
export { default as ShareIcon } from "./share.svg";
export { default as SearchIcon } from "./search.svg";
export { default as AllAppsIcon } from "./all-apps.svg";
export { default as QuestionIcon } from "./question.svg";
export { default as DownloadIcon } from "./download.svg";
export { default as InputSendIcon } from "./input-send.svg";
export { default as SelectArrow } from "./select-arrow.svg";
export { default as ArrowBackIcon } from "./arrow-back.svg";
export { default as CategoriesIcon } from "./categories.svg";
export { default as CompilationsIcon } from "./compilations.svg";
