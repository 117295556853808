import { useOpenLink } from "@hooks/useOpenLink";

import { App } from "@interfaces/api";
import { isEmpty } from "@utils/string.helpers";
import { getDateFromString } from "@utils/dates.helper";

import { APP_LICENSE_COLLECTION } from "@constants/app";

export const getInfoData = (app: App) => {
  const onWebsiteClick = useOpenLink();

  return [
    {
      label: "Разработчик:",
      value: isEmpty(app.developer) ? "-" : app.developer,
    },
    {
      label: "Тип лицензии:",
      value: isEmpty(app.licenseType)
        ? "-"
        : APP_LICENSE_COLLECTION.find(({ value }) => value === app.licenseType)
            ?.label || "-",
    },
    {
      label: "Сайт разработчика:",
      value: app.developerWebsite,
      onClick: () => onWebsiteClick(app.developerWebsite),
    },
    {
      label: "Категории:",
      value: app.categories.length
        ? app.categories.map((cat) => cat.name).join(", ")
        : "-",
    },
    {
      label: "Дата загрузки:",
      value: getDateFromString(app.createdAt),
    },
  ];
};
