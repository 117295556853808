import urlJoin from "url-join";
import { BASE_PATH } from "@constants/url";

type GetFullUrlType = (
  relativeUrl?: string,
  params?: { [param: string]: string | number },
) => string;

export const getFullUrl: GetFullUrlType = (path = "", params = {}) => {
  const urlWithParams = new URL(urlJoin(BASE_PATH, path ?? ""));

  Object.entries(params).forEach(([key, value]) =>
    urlWithParams.searchParams.set(key, `${value}`),
  );

  return urlWithParams.toString();
};

type GetThumbnailUrl = (path: string) => string;
export const getThumbnailUrl: GetThumbnailUrl = (path) =>
  getFullUrl(path, { format: "thumbnail" });
