/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import {
  ERRORS,
  DEVICE_METHOD,
  DeviceApi,
  createDeviceSocket,
  SignedActionPayload,
} from "@utils/device.helpers";
import { SocketStatuses } from "@interfaces/common";

export type InstalledApps = Map<string, string>;
export type InProcessingApps = Map<
  string,
  "installing" | "deleting" | "opening"
>;
export type Connection = { socket: WebSocket; api: DeviceApi };

export type AppActionResponse = {
  statusCode: number;
  packageName: string;
  errorMessage: string;
};

export type DeviceSliceState = {
  error: string | null;
  socket: WebSocket | null;
  installedApps: InstalledApps;
  inProcessingApp: InProcessingApps;
  deviceApiInstance: DeviceApi | null;
  connectionStatus: SocketStatuses | null;
};

const initialState: DeviceSliceState = {
  error: null,
  socket: null,
  installedApps: new Map(),
  inProcessingApp: new Map(),
  connectionStatus: null,
  deviceApiInstance: null,
};

export const initDeviceConnection = createAsyncThunk<
  Connection,
  undefined,
  { state: { device: DeviceSliceState } }
>("device/initDeviceConnection", async (_, { dispatch }) => {
  const connection = await createDeviceSocket();

  // Will be called after executing the device method
  connection.api.notifyResult.connect((method: string, res: string) => {
    switch (method) {
      case DEVICE_METHOD.LIST_APPS: {
        const installedApps = res
          .replace(/ /g, "")
          .replace(/^-\d*:/, "")
          .split("\n")
          .reduce<InstalledApps>((acc, app) => acc.set(app, app), new Map());

        dispatch(setInstalledApps(installedApps));
        break;
      }
      case DEVICE_METHOD.START_APP: {
        const payload: AppActionResponse = JSON.parse(res);
        const { errorMessage, packageName } = payload;
        const instaledPackages = packageName.split(" ");

        instaledPackages.forEach((p) => dispatch(removeFromProcessing(p)));

        if (errorMessage) {
          enqueueSnackbar(`Ошибка при запусе ${packageName}`, {
            variant: "error",
          });
        }
        break;
      }
      case DEVICE_METHOD.INSTALL_APP: {
        const payload: AppActionResponse = JSON.parse(res);
        const { errorMessage, packageName } = payload;
        const instaledPackages = packageName.split(" ");

        instaledPackages.forEach((p) => dispatch(removeFromProcessing(p)));

        if (errorMessage) {
          enqueueSnackbar(`Ошибка при установке ${packageName}`, {
            variant: "error",
          });
        } else {
          instaledPackages.forEach((p) => dispatch(addToInstalledApps(p)));
          enqueueSnackbar(`Приложение ${packageName} успешно установлено`, {
            variant: "success",
          });
        }
        break;
      }
      case DEVICE_METHOD.REMOVE_APP: {
        const payload: AppActionResponse = JSON.parse(res);
        const { errorMessage, packageName } = payload;
        const removedPackages = packageName.split(" ");

        removedPackages.forEach((p) => dispatch(removeFromProcessing(p)));

        if (errorMessage) {
          enqueueSnackbar(`Ошибка при удалении ${packageName}`, {
            variant: "error",
          });
        } else {
          removedPackages.forEach((p) => dispatch(removeFromInstalledApps(p)));
          enqueueSnackbar(`Приложение ${packageName} успешно удалено`, {
            variant: "success",
          });
        }
        break;
      }
      default:
        break;
    }
  });

  return connection;
});

const DeviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    closeDeviceConnection(state) {
      state.socket?.close();
      state = initialState;
    },
    setInstalledApps(state, action: PayloadAction<InstalledApps>) {
      state.installedApps = action.payload;
    },
    startApp(state, action: PayloadAction<string>) {
      action.payload
        .split(" ")
        .forEach((p) => state.inProcessingApp.set(p, "opening"));
      state.deviceApiInstance?.startApp(action.payload);
    },
    installApp(state, action: PayloadAction<SignedActionPayload>) {
      action.payload.packageName
        .split(" ")
        .forEach((p) => state.inProcessingApp.set(p, "installing"));
      state.deviceApiInstance?.aptInstallApp(JSON.stringify(action.payload));
    },
    removeApp(state, action: PayloadAction<SignedActionPayload>) {
      action.payload.packageName
        .split(" ")
        .forEach((p) => state.inProcessingApp.set(p, "deleting"));
      state.deviceApiInstance?.aptRemoveApp(JSON.stringify(action.payload));
    },
    openLinkInBrowser(state, action: PayloadAction<string>) {
      state.deviceApiInstance?.startAppUrl(action.payload);
    },
    addToInstalledApps(state, action: PayloadAction<string>) {
      state.installedApps.set(action.payload, action.payload);
    },
    removeFromInstalledApps(state, action: PayloadAction<string>) {
      state.installedApps.delete(action.payload);
    },
    removeFromProcessing(state, action: PayloadAction<string>) {
      state.inProcessingApp.delete(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initDeviceConnection.pending, (state) => {
      state.connectionStatus = SocketStatuses.CONNECTING;
    });
    builder.addCase(
      initDeviceConnection.fulfilled,
      (state, action: PayloadAction<Connection>) => {
        state.socket = action.payload.socket;
        state.deviceApiInstance = action.payload.api;
        state.connectionStatus = SocketStatuses.OPEN;

        state.deviceApiInstance.getInstalledApps();
      },
    );
    builder.addCase(initDeviceConnection.rejected, (state) => {
      state.socket?.close();
      state.error = ERRORS.FETCH_FAILED;
      state.connectionStatus = SocketStatuses.CLOSED;
    });
  },
});

export const {
  startApp,
  removeApp,
  installApp,
  setInstalledApps,
  openLinkInBrowser,
  addToInstalledApps,
  removeFromProcessing,
  closeDeviceConnection,
  removeFromInstalledApps,
} = DeviceSlice.actions;

export default DeviceSlice.reducer;
