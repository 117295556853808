import { Box } from "@mui/material";
import ReviewCard from "@components/UI/ReviewCard";

import { CompilationsArrayWithAppsType } from "@interfaces/api";

import classes from "./ReviewContentContainer.module.scss";

export interface ReviewContentContainerProps {
  banners: CompilationsArrayWithAppsType;
}

function ReviewContentContainer({ banners }: ReviewContentContainerProps) {
  return (
    <Box className={classes.root}>
      {banners.map(({ id, name, description, background }) => (
        <ReviewCard
          key={id}
          id={id}
          name={name}
          description={description}
          background={background}
        />
      ))}
    </Box>
  );
}

export default ReviewContentContainer;
