export const APP_LICENSE = {
  COMMERCIAL: {
    label: "Коммерческие",
    options: [{ label: "Commercial", value: "commercial" }],
  },
  OPEN: {
    label: "Открытые",
    options: [{ label: "Open Source", value: "openSource" }],
  },
  CONDITIONALLY_FREE: {
    label: "Условно-бесплатные",
    options: [
      { label: "Demoware", value: "demoware" },
      { label: "ShareWare", value: "shareWare" },
      { label: "TrialWare", value: "trialWare" },
    ],
  },
  FREE: {
    label: "Бесплатные",
    options: [
      { label: "GPL", value: "gpl" },
      { label: "Adware", value: "adware" },
      { label: "Freeware", value: "freeware" },
      { label: "Nagware/Begware", value: "nagware" },
      { label: "Postcardware", value: "postcardware" },
      { label: "Donationware", value: "donationware" },
    ],
  },
};

export const APP_LICENSE_COLLECTION = Object.values(APP_LICENSE).reduce(
  (acc, { options }) => [...acc, ...options],
  [] as { label: string; value: string }[],
);
