import { useState } from "react";
import useObserver from "@hooks/useObserver";
import useCompilationsFeed from "@hooks/useCompilationsFeed";
import { compilationsSelector } from "@store/selectors/compilations";

import { Box, Typography } from "@mui/material";
import LoadingContainer from "@components/LoadingContainer";
import CardsContainer from "@components/CardsContainer/CardsContainer";
import ReviewContentContainer from "@components/ReviewContentContainer";

import styles from "./CompilationsPage.module.scss";

function CompilationsPage() {
  const [page, setPage] = useState(1);

  const { compilations } = compilationsSelector();
  const { compilationsFeed, isFeedLoading, isFeedEmpty } =
    useCompilationsFeed(page);

  const [handleSetLastElementRef] = useObserver({
    cb: () => setPage((prevPage) => prevPage + 1),
    isLoading: isFeedLoading,
    hasMore:
      compilationsFeed.usual.length <
      compilations.length - compilationsFeed.banner.length,
  });

  return (
    <LoadingContainer isLoading={isFeedLoading}>
      <Box className={styles.root}>
        <Box className={styles.header}>
          <Typography variant="h1">Подборки</Typography>
        </Box>

        {isFeedEmpty ? (
          <Typography variant="h1">Не найдено ни одной подборки</Typography>
        ) : null}

        <ReviewContentContainer banners={compilationsFeed.banner} />

        {compilationsFeed.usual.map(
          ({ id, name, description, apps, background }, i, arr) =>
            arr.length === i + 1 ? (
              <CardsContainer
                key={id}
                id={id}
                title={name}
                cards={apps}
                background={background}
                description={description}
                ref={handleSetLastElementRef}
              />
            ) : (
              <CardsContainer
                key={id}
                id={id}
                title={name}
                cards={apps}
                background={background}
                description={description}
              />
            ),
        )}
      </Box>
    </LoadingContainer>
  );
}

export default CompilationsPage;
