import { ReactNode } from "react";
import { Typography } from "@mui/material";
import CustomNavLink from "../CustomNavLink";

import classes from "./SidebarElement.module.scss";

export interface SidebarElementProps {
  name: string;
  icon: ReactNode;
  path: string;
  disabled?: boolean;
  target?: string;
  onClick?: (url: string) => void;
}

function SidebarElement(props: SidebarElementProps) {
  const { name, icon, path, disabled, target, onClick } = props;
  return (
    <li>
      <CustomNavLink
        to={path}
        target={target}
        onClick={onClick}
        disabled={disabled}
        className={classes.root}
        activeClassName={classes.linkActive}
      >
        {icon}
        <Typography variant="body1">{name}</Typography>
      </CustomNavLink>
    </li>
  );
}

export default SidebarElement;
