/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

interface UseFetchInput<A, B> {
  initial: A;
  params?: B;
  cb: Function;
  deps?: Array<any>;
}

type UseFetchOutput<A> = [
  A,
  React.Dispatch<React.SetStateAction<A>>,
  boolean,
  string,
];

// A - type of your state data
// B - type of your fetching callback params. Optiional.

const useFetch = <A, B = {}>({
  cb,
  initial,
  params,
  deps = [],
}: UseFetchInput<A, B>): UseFetchOutput<A> => {
  const [data, setData] = useState<A>(initial);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dataError, setDataError] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isLoading) setIsLoading(true);
        const response = await cb(params);
        setData(response);
      } catch (error) {
        if (typeof error === "string") {
          setDataError(error);
        } else if (error instanceof Error) {
          setDataError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [...deps]);

  return [data, setData, isLoading, dataError];
};

export default useFetch;
