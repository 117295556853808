import { useEffect, useState } from "react";

import { compilationsSelector } from "@store/selectors/compilations";

import { HomePageFeed } from "@interfaces/common";
import { CompilationWithApps } from "@interfaces/api";

import appAPI from "@api/application";

import { getSafeArray } from "@utils/array.helpers";

import { DEFAULT_PAGE_PARAMS } from "@constants/common";
// import ARTIFICIAL_COMPILATIONS from "@schemas/artificialCompilations";
import { CARD_RANK_VALUES, BANNER_RANK_VALUES } from "@constants/compilation";

interface CompilationsWithAppsByRank {
  [index: string]: CompilationWithApps;
}

const useHomeFeed = () => {
  const { compilations, isCompilationsLoading, isCompilationsEmpty } =
    compilationsSelector();

  const [isFeedLoading, setIsFeedLoading] = useState<boolean>(true);
  const [homeFeed, setHomeFeed] = useState<HomePageFeed>({
    banners: [],
    feed: [],
  });

  useEffect(() => {
    if (isCompilationsLoading) {
      return;
    }

    if (isCompilationsEmpty) {
      setIsFeedLoading(false);
      return;
    }

    const fetchApps = async () => {
      try {
        const compilationsWithAppsByRank = await compilations.reduce(
          async (acc, compilation) => {
            const { id, rank, iconRef, compilationBannerRef } = compilation;

            const result = await acc;

            if (BANNER_RANK_VALUES.includes(rank)) {
              result[rank] = {
                ...compilation,
                apps: [],
                background: compilationBannerRef,
              };

              return result;
            }

            if (CARD_RANK_VALUES.includes(rank)) {
              const apps = await appAPI.listApps({
                ...DEFAULT_PAGE_PARAMS,
                compilationId: id,
              });

              if (!apps.length) {
                return result;
              }

              result[rank] = {
                ...compilation,
                background: iconRef,
                apps,
              };

              return result;
            }

            return result;
          },
          Promise.resolve<CompilationsWithAppsByRank>({}),
        );

        // const [mostDownloaded, highestReating] = await Promise.all(
        //   ARTIFICIAL_COMPILATIONS.map(async (compilation) => ({
        //     ...compilation,
        //     apps: await appAPI.listApps({
        //       ...DEFAULT_PAGE_PARAMS,
        //       sortBy: compilation.sortBy,
        //     }),
        //   })),
        // );

        const {
          1: firstBanner,
          2: secondBanner,
          3: firstCard,
          4: secondCard,
        } = compilationsWithAppsByRank;

        setHomeFeed({
          banners: getSafeArray(firstBanner, secondBanner),
          feed: [
            // mostDownloaded,
            ...getSafeArray(firstCard),
            // highestReating,
            ...getSafeArray(secondCard),
          ],
        });
      } catch (error) {
        console.error(error);
      } finally {
        setIsFeedLoading(false);
      }
    };

    fetchApps();
  }, [compilations, isCompilationsEmpty, isCompilationsLoading]);

  return {
    homeFeed,
    isFeedLoading,
    isFeedEmpty: !homeFeed.banners.length && !homeFeed.feed.length,
  };
};

export default useHomeFeed;
