import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { Box } from "@mui/material";

import classes from "./CarouselButton.module.scss";

export interface CarouselButtonProps {
  direction: string;
}

function CarouselButton({ direction }: CarouselButtonProps) {
  return direction === "left" ? (
    <Box className={classes.root}>
      <KeyboardArrowLeftIcon />
    </Box>
  ) : (
    <Box className={classes.root}>
      <KeyboardArrowRightIcon />
    </Box>
  );
}

export default CarouselButton;
