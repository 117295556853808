import { configureStore } from "@reduxjs/toolkit";
import { enableMapSet } from "immer";
import { useDispatch } from "react-redux";

import appsReducer from "./AppsSlice";
import deviceSlice from "./DeviceSlice";
import categoriesReducer from "./CategoriesSlice";
import compilationsReducer from "./CompilationsSlice";

enableMapSet();

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    apps: appsReducer,
    device: deviceSlice,
    categories: categoriesReducer,
    compilations: compilationsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => AppDispatch = useDispatch;
