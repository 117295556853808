import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CircularLoader from "../UI/CircularLoader";

import classes from "./loadingContainer.module.scss";

export interface LoadingContainerProps {
  isLoading: boolean;
  isFirstFetching?: boolean;
  children: React.ReactNode;
}

function LoadingContainer({
  isLoading,
  children,
  isFirstFetching = true,
}: LoadingContainerProps) {
  const getContent = () =>
    isLoading && isFirstFetching ? (
      <Box className={classes.loaderWrapper}>
        <CircularProgress size={50} />
      </Box>
    ) : (
      <>
        {children}
        <CircularLoader isLoading={isLoading} />
      </>
    );

  return getContent();
}

export default LoadingContainer;
