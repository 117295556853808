import cn from "classnames";

import { Box, CircularProgress } from "@mui/material";

import classes from "./CircularLoader.module.scss";

export interface CircularLoaderProps {
  isLoading: boolean;
}

function CircularLoader({ isLoading }: CircularLoaderProps) {
  return (
    <Box className={cn(classes.root, { [classes.hide]: !isLoading })}>
      <CircularProgress size={50} />
    </Box>
  );
}

export default CircularLoader;
