import { useEffect } from "react";
import { Provider } from "react-redux";
import { store } from "@store";

import {
  initDeviceConnection,
  closeDeviceConnection,
} from "@store/DeviceSlice";
import { fetchApps } from "@store/AppsSlice";
import { fetchCategories } from "@store/CategoriesSlice";
import { fetchCompilations } from "@store/CompilationsSlice";

export const withStore = (component: () => React.ReactNode) =>
  function StoreProvider() {
    store.dispatch(fetchApps());
    store.dispatch(fetchCategories());
    store.dispatch(fetchCompilations());
    store.dispatch(initDeviceConnection());

    useEffect(() => {
      return () => {
        store.dispatch(closeDeviceConnection());
      };
    }, []);

    return <Provider store={store}>{component()}</Provider>;
  };
