import { useNavigate } from "react-router-dom";
import { useOpenLink } from "@hooks/useOpenLink";

import { Box, IconButton, Typography } from "@mui/material";
import { ArrowBackIcon } from "@assets/icons";
import { AppActionButton } from "@components/UI/AppActionButton/";

import { getThumbnailUrl } from "@utils/url.helpers";
import { App } from "@interfaces/api";

import classes from "./ApplicationHeader.module.scss";

export interface ApplicationHeaderProps {
  app: App;
}

function ApplicationHeader({ app }: ApplicationHeaderProps) {
  const { title, iconRef, developer, developerWebsite } = app;
  const navigate = useNavigate();
  const onWebsiteClick = useOpenLink();

  const handleGoBackClick = () => navigate(-1);

  const iconSrc = getThumbnailUrl(iconRef);

  return (
    <Box className={classes.root}>
      <IconButton onClick={handleGoBackClick} sx={{ width: "max-content" }}>
        <ArrowBackIcon />
      </IconButton>

      <Box className={classes.appMain}>
        <Box className={classes.logo}>
          <img alt="icon" src={iconSrc} />
        </Box>

        <Box className={classes.appInfo}>
          <Typography variant="h1">{title}</Typography>
          <Typography
            variant="caption"
            className={classes.developerWebsite}
            onClick={() => onWebsiteClick(developerWebsite)}
          >
            {developer}
          </Typography>

          <AppActionButton className={classes.button} app={app} />
        </Box>
      </Box>
    </Box>
  );
}

export default ApplicationHeader;
