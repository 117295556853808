import { deviceSelector } from "@store/selectors/device";
import { openLinkInBrowser } from "@store/DeviceSlice";
import { useAppDispatch } from "./reduxTypedHooks";

export const useOpenLink = () => {
  const { isDevice } = deviceSelector();
  const dispatch = useAppDispatch();

  return isDevice
    ? (url: string) => dispatch(openLinkInBrowser(url))
    : (url: string) => window.open(url, "_blank");
};
