import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import { getThumbnailUrl } from "@utils/url.helpers";
import { PAGES } from "@shared/constants";

import classes from "./CardCategory.module.scss";

export interface CategoryCardProps {
  id: number;
  name: string;
  count: number;
  iconRef: string;
}

export function CardCategory({ id, count, name, iconRef }: CategoryCardProps) {
  const to = `${PAGES.CATEGORIES}/${id}`;
  const info = `${name} (${count})`;
  const linkState = { categoryName: name };
  const iconSrc = getThumbnailUrl(iconRef);

  return (
    <Link className={classes.root} to={to} state={linkState}>
      <Box className={classes.iconWrapper}>
        <img alt="icon" src={iconSrc} />
      </Box>

      <Typography className={classes.info} variant="body2" title={info}>
        {info}
      </Typography>
    </Link>
  );
}
