import useHomeFeed from "@hooks/useHomeFeed";

import { categoriesSelector } from "@selectors/categories";

import CardsContainer from "@components/CardsContainer";
import LoadingContainer from "@components/LoadingContainer";
import MiniCardsContainer from "@components/MiniCardsContainer";
import ReviewContentContainer from "@components/ReviewContentContainer";
import CategoriesCardsContainer from "@components/CategoriesCardsContainer";

import { Box, Typography } from "@mui/material";

import classes from "./Home.module.scss";

function Home() {
  const { categories } = categoriesSelector();
  const { homeFeed, isFeedEmpty, isFeedLoading } = useHomeFeed();

  return (
    <LoadingContainer isLoading={isFeedLoading}>
      <Box className={classes.root}>
        <Box className={classes.header}>
          <Typography variant="h1">Обзор</Typography>
        </Box>

        {isFeedEmpty ? (
          <Typography variant="h2">Не найдено ни одной подборки</Typography>
        ) : null}

        {homeFeed.banners.length ? (
          <ReviewContentContainer banners={homeFeed.banners} />
        ) : null}

        {homeFeed.feed.map(
          ({ id, name, description, apps, background, sortBy, isArtificial }) =>
            isArtificial ? (
              <MiniCardsContainer
                key={id}
                id={id}
                title={name}
                cards={apps}
                containerLinkQuery={{ sortBy }}
                containerLink="/all-applications"
              />
            ) : (
              <CardsContainer
                key={id}
                id={id}
                title={name}
                cards={apps}
                description={description}
                background={background}
              />
            ),
        )}

        <CategoriesCardsContainer categories={categories} />
      </Box>
    </LoadingContainer>
  );
}

export default Home;
