import axios, { AxiosRequestConfig } from "axios";
import { BASE_API_PATH } from "@constants/url";

const config: AxiosRequestConfig = {
  baseURL: BASE_API_PATH,
  timeout: 30000,
};

const appstoreAPI = axios.create(config);

export default appstoreAPI;
