import { useMemo } from "react";
import cn from "classnames";

import { getAppInfo } from "@utils/app.helpers";
import { getThumbnailUrl } from "@utils/url.helpers";
import { deviceSelector } from "@store/selectors/device";

import { ShortInfoApp } from "@interfaces/api";

import { Box, Typography } from "@mui/material";
import { AppActionButton } from "../AppActionButton";

import classes from "./CardMyApp.module.scss";

export interface CardMyAppProps {
  app: ShortInfoApp;
}

function CardMyApp({ app }: CardMyAppProps) {
  const { title, iconRef } = app;
  const { installedApps, inProcessingApp } = deviceSelector();

  const { appStatus } = useMemo(
    () =>
      getAppInfo({
        installedApps,
        inProcessingApp,
        packageName: app.packageName,
      }),
    [inProcessingApp, installedApps, app],
  );

  const iconSrc = getThumbnailUrl(iconRef);

  return (
    <Box
      className={cn(classes.root, {
        [classes.deleting]: appStatus === "deleting",
      })}
    >
      <Box className={classes.info}>
        <Box className={classes.iconWrapper}>
          <img alt="icon" src={iconSrc} />
        </Box>

        <Box>
          <Typography variant="body1" fontWeight={600}>
            {title}
          </Typography>
        </Box>
      </Box>

      <Box className={classes.buttons}>
        <AppActionButton app={app} className={classes.button} />
        <AppActionButton app={app} mode="deleteIcon" />
      </Box>
    </Box>
  );
}

export default CardMyApp;
