// common API
export interface PagedElementsType {
  pageMeta: {
    currentPage: number;
    pageSize: number;
    pageItems: number;
    totalPages: number;
    totalCount: number;
  };
  elements: Array<any>;
}

export type GetByIdParamsType = { id: number };

// Application
export interface AppCategory {
  id: number;
  name: string;
  iconRef: string;
}

export interface AppCompilation {
  id: number;
  name: string;
  iconRef: string;
}
export interface App {
  id: number;
  title: string;
  subTitle: string;
  description: string;
  licenseType: string;
  developer: string;
  developerWebsite: string;
  developerEmail: string;
  developerPhoneNumber: string;
  packageName: string;
  launchName: string;
  iconRef: string;
  screenshotRefs: Array<string>;
  rating: number;
  downloads: number;
  categories: Array<AppCategory>;
  compilations: Array<AppCompilation>;
  createdAt: string;
  isInstaled?: boolean;
  packageNameSignature: string;
}

export interface ShortInfoApp {
  id: number;
  title: string;
  subTitle: string;
  iconRef: string;
  rating: number;
  downloads: number;
  packageName: string;
  launchName: string;
  packageNameSignature: string;
}

export interface PagedApps {
  pageMeta: {
    currentPage: number;
    pageSize: number;
    pageItems: number;
    totalPages: number;
    totalCount: number;
  };
  elements: Array<ShortInfoApp>;
}

export interface ListPagedAppsFuncParams {
  categoryId?: number;
  compilationId?: number;
  searchString?: string;
  sortBy?: string;
  page: number;
  perPage: number;
}

export enum AppFilterField {
  ID = "id",
  RANK = "rank",
  TITLE = "title",
  RATING = "rating",
  DOWNLOADS = "downloads",
}

export interface AppFilterType {
  orderBy: AppFilterField;
}

export type AppsArrayType = Array<App>;
export type CategoryAppsArrayType = Array<ShortInfoApp>;
export type CompilationAppsArrayType = Array<ShortInfoApp>;

export type GetAppFuncType = (params: GetByIdParamsType) => Promise<App>;
export type ListAppByCategoryFuncType = (
  params: GetByIdParamsType,
) => Promise<CategoryAppsArrayType>;
export type ListAppsByCompilationFuncType = (
  params: GetByIdParamsType,
) => Promise<CompilationAppsArrayType>;
export type ListPagedAppsFuncType = (
  query: ListPagedAppsFuncParams,
) => Promise<PagedApps>;
export type ListAppsFuncType = (
  query: ListPagedAppsFuncParams,
) => Promise<Array<ShortInfoApp>>;

// Category
export interface Category {
  id: number;
  name: string;
  description: string;
  iconRef: string;
  count: number;
  rank: number;
}

export interface PagedCategories {
  pageMeta: {
    currentPage: number;
    pageSize: number;
    pageItems: number;
    totalPages: number;
    totalCount: number;
  };
  elements: Array<Category>;
}

export interface CategoryWithApps {
  id: number;
  name: string;
  apps: Array<ShortInfoApp>;
}

export interface ListPagedCategoriesFuncParams {
  sortBy: Array<string>;
  page: number;
  perPage: number;
}

export type CategoriesArrayType = Array<Category>;
export type CategoriesWithApps = Array<CategoryWithApps>;

export type GetCategoryFuncType = (
  params: GetByIdParamsType,
) => Promise<Category>;
export type ListCategoriesFuncType = () => Promise<CategoriesArrayType>;
export type ListPagedCategoriesFuncType = (
  query: ListPagedCategoriesFuncParams,
) => Promise<PagedCategories>;

// Compilation
export enum CompilationFilterField {
  ID = "id",
  RANK = "rank",
  TITLE = "title",
  COUNT = "count",
}
export interface Compilation {
  id: number;
  name: string;
  rank: number;
  count: number;
  description: string;
  iconRef: string;
  compilationBannerRef: string;
}

export interface PagedCompilations {
  pageMeta: {
    currentPage: number;
    pageSize: number;
    pageItems: number;
    totalPages: number;
    totalCount: number;
  };
  elements: Array<Compilation>;
}

export interface ListPagedCompilationsFuncParams {
  sortBy: Array<string>;
  page: number;
  perPage: number;
}

export interface CompilationWithApps {
  id: number;
  name: string;
  rank: number;
  sortBy?: string;
  description: string;
  background: string;
  isArtificial?: boolean;
  apps: Array<ShortInfoApp>;
}

export interface CompilationsByType {
  banner: Array<CompilationWithApps>;
  usual: Array<CompilationWithApps>;
}

export type CompilationsArrayType = Array<Compilation>;
export type CompilationsArrayWithAppsType = Array<CompilationWithApps>;

export type GetCompilationFuncType = (
  params: GetByIdParamsType,
) => Promise<Compilation>;

export type ListCompilationsFuncType = (
  sortBy: string,
) => Promise<CompilationsArrayType>;

export type ListPagedCompilationsFuncType = (
  query: ListPagedCompilationsFuncParams,
) => Promise<PagedCompilations>;
