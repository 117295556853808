import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";

import { PAGES } from "@shared/constants";
import { ShortInfoApp } from "@interfaces/api";
import { getThumbnailUrl } from "@utils/url.helpers";

import { Box, Typography } from "@mui/material";
import { AppActionButton } from "../AppActionButton";

import classes from "./CardApp.module.scss";

export interface CardAppProps {
  app: ShortInfoApp;
}

const CardApp = forwardRef<HTMLDivElement, CardAppProps>(({ app }, ref) => {
  const navigate = useNavigate();

  const { id, title, subTitle, iconRef } = app;
  const iconSrc = getThumbnailUrl(iconRef);

  const handleCardClick = () => {
    navigate(`${PAGES.APPLICATIONS}/${id}`);
  };

  return (
    <Box ref={ref} className={classes.root} onClick={handleCardClick}>
      <Box className={classes.iconWrapper}>
        <img alt="icon" src={iconSrc} />
      </Box>

      <Box className={classes.infoWrapper}>
        <Typography
          className={classes.ellipsis1}
          variant="body1"
          fontWeight={600}
          title={title}
        >
          {title}
        </Typography>
        <Typography
          className={classes.ellipsis2}
          variant="caption"
          title={subTitle}
        >
          {subTitle}
        </Typography>

        <AppActionButton className={classes.actionButton} app={app} />
      </Box>
    </Box>
  );
});

export default CardApp;
