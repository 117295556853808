import compose from "compose-function";

import { withStore } from "./withStore";
import { withSnackbar } from "./withSnackbar";
import { withTheme } from "./withTheme";

import "bootstrap/dist/css/bootstrap.min.css";
import "@assets/css/index.scss";

export const withProviders = compose(withStore, withSnackbar, withTheme);
