import { Box, Typography } from "@mui/material";
import classes from "./ErrorPage.module.scss";

function ErrorPage() {
  return (
    <Box className={classes.root}>
      <Typography variant="h1">Sorry page not found!</Typography>
    </Box>
  );
}

export default ErrorPage;
