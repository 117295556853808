import { useAppSelector } from "@hooks/reduxTypedHooks";
import { SocketStatuses } from "@interfaces/common";

export const deviceSelector = () =>
  useAppSelector((state) => {
    const {
      error,
      installedApps,
      inProcessingApp,
      connectionStatus,
      deviceApiInstance,
    } = state.device;

    return {
      error,
      installedApps,
      inProcessingApp,
      isDevice: navigator.userAgent.includes("QtWebEngine"),
      isDeviceReady:
        Boolean(deviceApiInstance) && connectionStatus === SocketStatuses.OPEN,
    };
  });
