import { Outlet } from "react-router-dom";

import { Box } from "@mui/material";
import Sidebar from "@components/UI/Sidebar";
import ScrollContainer from "@components/ScrollContainer";

import classes from "./Layout.module.scss";

function Layout() {
  return (
    <Box className={classes.root}>
      <Sidebar />
      <ScrollContainer>
        <Outlet />
      </ScrollContainer>
    </Box>
  );
}

export default Layout;
