import { forwardRef } from "react";
import { Link } from "react-router-dom";

import { ShortInfoApp } from "@interfaces/api";

import { PAGES } from "@shared/constants";
import { Box, Button, Typography } from "@mui/material";
import MiniCardApp from "../UI/MiniCardApp";

import classes from "./MiniCardsContainer.module.scss";

export interface MinCardsContainerProps {
  id: number;
  title: string;
  cards: ShortInfoApp[];
  containerLinkQuery?: object;
  containerLink?: string;
}

const MiniCardsContainer = forwardRef<HTMLDivElement, MinCardsContainerProps>(
  ({ id, title, cards, containerLink, containerLinkQuery }, ref) => {
    const link = containerLink || `${PAGES.CATEGORIES}/${id}`;
    const state = { ...containerLinkQuery, categoryName: title };

    return (
      <Box ref={ref} className={classes.root}>
        <Box className={classes.header}>
          <Typography variant="h2">{title}</Typography>
          <Button state={state} component={Link} to={link}>
            См. все
          </Button>
        </Box>

        <Box className={classes.cards}>
          {cards.map((app) => (
            <MiniCardApp key={app.id} app={app} />
          ))}
        </Box>
      </Box>
    );
  },
);

export default MiniCardsContainer;
