/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { PagedElementsType } from "@interfaces/api";

interface UseFetchByPageInput<A, B> {
  initial: A;
  params?: B;
  cb: Function;
  deps?: Array<any>;
}

type UseFetchByPageOutput<A> = [
  A,
  React.Dispatch<React.SetStateAction<A>>,
  boolean,
  boolean,
  string,
];

const useFetchByPage = <A extends PagedElementsType, B = {}>({
  cb,
  initial,
  params,
  deps = [],
}: UseFetchByPageInput<A, B>): UseFetchByPageOutput<A> => {
  const [data, setData] = useState<A>(initial);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dataError, setDataError] = useState<string>("");
  const hasMore = data.pageMeta.currentPage < data.pageMeta.totalPages;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isLoading) setIsLoading(true);
        const response: PagedElementsType = await cb(params);
        setData((prev) => ({
          ...prev,
          ...response,
          elements: hasMore
            ? [...prev.elements, ...response.elements]
            : [...response.elements],
        }));
      } catch (error) {
        if (typeof error === "string") {
          setDataError(error);
        } else if (error instanceof Error) {
          setDataError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [...deps]);

  return [data, setData, hasMore, isLoading, dataError];
};

export default useFetchByPage;
