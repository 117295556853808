import appstoreAPI from "@utils/axios";
import {
  GetCategoryFuncType,
  ListCategoriesFuncType,
  ListPagedCategoriesFuncType,
} from "@interfaces/api";

const getCategory: GetCategoryFuncType = async ({ id }) => {
  const response = await appstoreAPI.get(`categories/${id}`);
  return response.data;
};

const listCategories: ListCategoriesFuncType = async () => {
  const response = await appstoreAPI.get("categories");
  return response.data;
};

const ListPagedCategories: ListPagedCategoriesFuncType = async (query) => {
  const response = await appstoreAPI.request({
    url: "categories/paged",
    method: "get",
    params: { ...query },
  });

  return response.data;
};

export default {
  getCategory,
  listCategories,
  ListPagedCategories,
};
