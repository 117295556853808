/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useCallback, ForwardedRef } from "react";
import { LastElementRefFunc } from "@interfaces/common";

interface UseObserverInput {
  cb: Function;
  hasMore: boolean;
  isLoading: boolean;
}

type UseObserverOutput = [ForwardedRef<HTMLDivElement>];

const useObserver = ({
  cb,
  hasMore,
  isLoading,
}: UseObserverInput): UseObserverOutput => {
  const observer = useRef<IntersectionObserver | null>(null);
  const refCallback: LastElementRefFunc = useCallback(
    (node) => {
      if (!node || isLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0] && entries[0].isIntersecting && hasMore) cb();
      });

      observer.current.observe(node);
    },
    [isLoading, hasMore],
  );

  return [refCallback as ForwardedRef<HTMLDivElement>];
};

export default useObserver;
