import { SnackbarProvider } from "notistack";

export const withSnackbar = (component: () => React.ReactNode) =>
  function Provider() {
    return (
      <SnackbarProvider anchorOrigin={{ horizontal: "right", vertical: "top" }}>
        {component()}
      </SnackbarProvider>
    );
  };
