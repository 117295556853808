import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";

import { PAGES } from "@shared/constants";
import { ShortInfoApp } from "@interfaces/api";
import { getThumbnailUrl } from "@utils/url.helpers";

import { Box, Typography } from "@mui/material";
import { AppActionButton } from "../AppActionButton";

import classes from "./MiniCardApp.module.scss";

export interface MinCardAppProps {
  app: ShortInfoApp;
}

const MiniCardApp = forwardRef<HTMLDivElement, MinCardAppProps>(
  ({ app }, ref) => {
    const navigate = useNavigate();

    const { id, title, subTitle, iconRef } = app;
    const iconSrc = getThumbnailUrl(iconRef);

    const handleCardClick = () => {
      navigate(`${PAGES.APPLICATIONS}/${id}`);
    };

    return (
      <Box ref={ref} className={classes.root} onClick={handleCardClick}>
        <Box className={classes.iconWrapper}>
          <img alt="icon" src={iconSrc} />
        </Box>

        <Box className={classes.blockAboutApp}>
          <Typography
            className={classes.ellipsis}
            fontWeight={600}
            variant="body1"
            title={title}
          >
            {title}
          </Typography>
          <Typography
            className={classes.ellipsis}
            variant="caption"
            title={subTitle}
          >
            {subTitle}
          </Typography>
        </Box>

        <AppActionButton className={classes.actionButton} app={app} />
      </Box>
    );
  },
);

export default MiniCardApp;
