import { CompilationsArrayWithAppsType } from "./api";

export type RegularObjectType = { [key: string]: string };
export type LastElementRefFunc = (node: HTMLElement) => void;
export interface HomePageFeed {
  feed: CompilationsArrayWithAppsType;
  banners: CompilationsArrayWithAppsType;
}

export enum FilterMethods {
  DESC = "desc",
  ASC = "asc",
}

export enum FetchStatuses {
  PENDING = "pending",
  RESOLVED = "resolved",
  REJECTED = "rejected",
}

export enum SocketStatuses {
  OPEN = "open",
  CLOSED = "closed",
  CLOSING = "closing",
  CONNECTING = "connecting",
}
