/* eslint-disable */
import { QWebChannel } from "./qwebchannel";

export const ERRORS = {
  CONNECTION_FAILED: "Невозможно открыть соединение!",
  FETCH_FAILED: "Список приложений недоступен!",
};

export const DEVICE_METHOD = {
  START_APP: "startApp",
  REMOVE_APP: "aptRemoveApp",
  INSTALL_APP: "aptInstallApp",
  LIST_APPS: "getInstalledApps",
};

export type SignedActionPayload = {
  packageName: string;
  packageNameSignature: string;
};

export interface DeviceApi {
  aptInstallApp: (data: string) => void;
  aptRemoveApp: (data: string) => void;
  startApp: (packageName: string) => void;
  getInstalledApps: () => void;
  startAppUrl: (url: string) => void;
  notifyResult: {
    connect: (callback: Function) => void;
    disconnect: (callback: Function) => void;
  };
}

type CreateDeviceSocketType = () => Promise<{
  socket: WebSocket;
  api: DeviceApi;
}>;

export const createDeviceSocket: CreateDeviceSocketType = async () =>
  new Promise((resolve, reject) => {
    const socket = new WebSocket("ws://localhost:3000/ws");

    socket.onopen = () =>
      new QWebChannel(socket, (channel: any) => {
        console.log("device socket opened");
        resolve({ socket, api: channel.objects.clientapp as DeviceApi });
      });

    socket.onclose = () => {
      console.log("device socket closed");
      reject(ERRORS.CONNECTION_FAILED);
    };

    socket.onerror = () => {
      console.error("device socket error");
      reject(ERRORS.CONNECTION_FAILED);
    };
  });
