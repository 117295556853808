import { FC } from "react";

import { ShortInfoApp } from "@interfaces/api";
import { getThumbnailUrl } from "@utils/url.helpers";

import { Box, IconButton, Modal, Button, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { LogoIcon } from "@assets/icons";

import classes from "./RemoveAppModal.module.scss";

export interface RemoveAppModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (e: any) => void;
  app: ShortInfoApp;
}

export const RemoveAppModal: FC<RemoveAppModalProps> = (props) => {
  const { open, app, onClose, onSubmit } = props;

  return (
    <Modal open={open} onClose={onClose} className={classes.root}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <LogoIcon />
          <Typography variant="body2" fontWeight={500}>
            Магазин
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>

        <Box className={classes.content}>
          <Box className={classes.info}>
            <img
              alt="app-icon"
              className={classes.icon}
              src={getThumbnailUrl(app.iconRef)}
            />
            <Box>
              <Typography variant="body2" fontWeight={500}>
                Вы действительно хотите удалить {app.title}?
              </Typography>
              <Typography variant="body2" color="#87879b">
                Данное действие удалит приложение и его настройки.
              </Typography>
            </Box>
          </Box>
          <Box className={classes.buttons}>
            <Button variant="outlined" onClick={onClose}>
              Отмена
            </Button>
            <Button variant="contained" onClick={onSubmit}>
              Удалить
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
