import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import { getFullUrl } from "@utils/url.helpers";
import { PAGES } from "@shared/constants";

import classes from "./ReviewCard.module.scss";

export interface ReviewCardProps {
  id: number;
  name: string;
  description: string;
  background: string;
}

function ReviewCard({ id, name, description, background }: ReviewCardProps) {
  const to = `${PAGES.COMPILATIONS}/${id}`;
  const linkState = { compilationName: name, background };
  const linkBgStyle = {
    backgroundImage: `url(${getFullUrl(background, {
      format: "thumbnail",
    })})`,
  };

  return (
    <Link
      to={to}
      state={linkState}
      style={linkBgStyle}
      className={classes.root}
    >
      <Box className={classes.bannerDescription}>
        <Typography variant="h1" color="white" fontWeight={800}>
          {name}
        </Typography>
        <Typography variant="body2" color="white">
          {description}
        </Typography>
      </Box>
    </Link>
  );
}

export default ReviewCard;
