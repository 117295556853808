/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { CategoriesArrayType } from "@interfaces/api";
import { FetchStatuses } from "@interfaces/common";

import categoryAPI from "@api/category";

type CategoriesState = {
  status: FetchStatuses | null;
  error: string | null;
  items: CategoriesArrayType;
};

const initialState: CategoriesState = {
  status: null,
  error: null,
  items: [],
};

export const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async () => categoryAPI.listCategories(),
);

const CategoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.pending, (state) => {
      state.status = FetchStatuses.PENDING;
      state.error = null;
    });
    builder.addCase(
      fetchCategories.fulfilled,
      (state, action: PayloadAction<CategoriesArrayType>) => {
        state.error = null;
        state.items = action.payload;
        state.status = FetchStatuses.RESOLVED;
      },
    );
    builder.addCase(fetchCategories.rejected, (state) => {
      state.status = FetchStatuses.REJECTED;
      state.error = "Error while fetch categories";
    });
  },
});

export default CategoriesSlice.reducer;
