/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { useRef, useState } from "react";
import cn from "classnames";

import { Box } from "@mui/material";
import { SelectArrow } from "@assets/icons";

import classes from "./Select.module.scss";

export interface OptionClickParams {
  name: string;
  value: number;
}

export interface SelectProps {
  value: number | string | undefined;
  valueField: string;
  labelField: string;
  defaultLabel?: string;
  fieldToChange: string;
  options: any[];
  onChange: (params: OptionClickParams) => void;
  isLoading?: boolean;
  className?: string;
}

function Select({
  value,
  options,
  onChange,
  isLoading,
  className,
  valueField,
  labelField,
  defaultLabel,
  fieldToChange,
}: SelectProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const select = useRef<HTMLDivElement | null>(null);

  const defaultOption = { [valueField]: 0, [labelField]: defaultLabel };
  const optionsWithDefault = [
    ...(defaultLabel ? [...options, defaultOption] : options),
  ].sort((a, b) => a.id - b.id);
  const activeOption =
    optionsWithDefault.find((option) => option[valueField] === value) ||
    defaultOption;

  const handleSelectClick = () => {
    if (isLoading) {
      select.current?.blur();
      return;
    }

    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = ({
    name,
    value: currentValue,
  }: OptionClickParams) => {
    onChange({
      name,
      value: Number.isInteger(+currentValue) ? +currentValue : currentValue,
    });
    select.current?.blur();
  };

  return (
    <Box
      ref={select}
      tabIndex={0}
      onFocus={handleSelectClick}
      onBlur={handleSelectClick}
      className={cn(classes.select, className, {
        [classes.disabled]: isLoading,
      })}
    >
      <Box className={classes.selectHeader}>
        <Box title={activeOption[labelField]} className={classes.activeOption}>
          {activeOption[labelField]}
        </Box>
        <Box className={cn({ [classes.rotateArrow]: isOpen })}>
          <SelectArrow />
        </Box>
      </Box>
      {isOpen && (
        <Box className={classes.selectOptions}>
          {optionsWithDefault.map((option) => (
            <Box
              key={option[valueField]}
              data-name={fieldToChange}
              data-label={option[labelField]}
              data-value={option[valueField]}
              title={option[labelField]}
              onClick={(e) =>
                handleOptionClick({ ...e.currentTarget.dataset } as any)
              }
              className={cn(classes.selectOption, {
                [classes.active]: value === option[valueField],
              })}
            >
              {option[labelField]}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default Select;
