import { useMemo } from "react";

import { appsSelector } from "@selectors/apps";
import { deviceSelector } from "@selectors/device";

import { Box, Typography } from "@mui/material";
import CardMyApp from "@components/UI/CardMyApp";
import LoadingContainer from "@components/LoadingContainer";

import { ShortInfoApp } from "@interfaces/api";

import classes from "./MyApplicationsPage.module.scss";

function MyApplicationsPage() {
  const { apps } = appsSelector();
  const { isDeviceReady, installedApps, error } = deviceSelector();

  const myApps: ShortInfoApp[] = useMemo(
    () =>
      apps.filter(({ packageName }) =>
        packageName.split(" ").every((p) => installedApps.has(p)),
      ),
    [apps, installedApps],
  );

  if (error) {
    return (
      <Box className={classes.root}>
        <Typography variant="h1">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Typography variant="h1">Мои приложения</Typography>
      </Box>

      <LoadingContainer isFirstFetching={!isDeviceReady} isLoading={false}>
        <Box className={classes.content}>
          {myApps.map((app) => (
            <CardMyApp key={app.id} app={app} />
          ))}
        </Box>
      </LoadingContainer>
    </Box>
  );
}

export default MyApplicationsPage;
