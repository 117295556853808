import { App } from "@interfaces/api";

export const APP_INITIAL: App = {
  id: 0,
  title: "",
  subTitle: "",
  description: "",
  developer: "",
  developerWebsite: "",
  developerEmail: "",
  developerPhoneNumber: "",
  packageName: "",
  launchName: "",
  iconRef: "",
  licenseType: "",
  screenshotRefs: [],
  categories: [],
  compilations: [],
  rating: 0,
  downloads: 0,
  createdAt: "",
  isInstaled: false,
  packageNameSignature: "",
};
